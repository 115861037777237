import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backend";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { useKingPrice, useTotalKingStaked } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import { ACTIVE_CHAIN_IDS, KLP_DECIMALS, KING_DECIMALS, USD_DECIMALS, arrayURLFetcher, getStatsInfo } from "lib/legacy";
import { bigNumberify, expandDecimals } from "lib/numbers";

import KlpManager from "abis/KlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  const { kingPrice } = useKingPrice(chainId, {}, active);
  const currentStats = getStatsInfo(dataStatsAll);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const klpManagerAddress = getContract(chainId, "KlpManager");

  const readerAddress = getContract(chainId, "Reader");
  const kingAddress = getContract(chainId, "KING");
  const klpAddress = getContract(chainId, "KLP");
  const usdkAddress = getContract(chainId, "USDK");
  const glpVesterAddress = getContract(chainId, "KlpVester");
  const kingVesterAddress = getContract(chainId, "KingVester");
  const tokensForSupplyQuery = [kingAddress, klpAddress, usdkAddress];

  const { data: totalKingSupplies, mutate: updateTotalKingSupplies } = useSWR(
    [`Dashboard:totalSuppliesKAVA:${chainId}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { data: aums } = useSWR([`Dashboard:getAums`, chainId, klpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, KlpManager),
  });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 5000,
    }
  );

  const { data: kingSupplyOfKingVester } = useSWR(
    [`Dashboard:kingSupplyOfKngVester:${active}`, chainId, kingAddress, "balanceOf", kingVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 5000,
    }
  );

  const { data: kingSupplyOfKlpVester } = useSWR(
    [`Dashboard:kingSupplyOfKlpVester:${active}`, chainId, kingAddress, "balanceOf", glpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 5000,
    }
  );

  const {
    total: totalKingStaked,
  } = useTotalKingStaked();
  let currentChainKingStaked = totalKingStaked;

  let klpPrice;
  let klpSupply;
  let klpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    klpSupply = totalSupplies[3];
    if (kingSupplyOfKingVester && kingSupplyOfKlpVester) {
      klpSupply = klpSupply.sub(kingSupplyOfKingVester).sub(kingSupplyOfKlpVester);
    }

    klpPrice =
      aum && aum.gt(0) && klpSupply.gt(0)
        ? aum.mul(expandDecimals(1, KLP_DECIMALS)).div(klpSupply)
        : expandDecimals(1, USD_DECIMALS);
    klpMarketCap = klpPrice.mul(klpSupply).div(expandDecimals(1, KLP_DECIMALS));
  }

  let tvl;
  if (currentChainKingStaked && aum && kingPrice) {
    tvl = kingPrice.mul(currentChainKingStaked).div(expandDecimals(1, KING_DECIMALS)).add(aum);
  }


  let totalTvl;
  if (klpMarketCap && kingPrice && totalKingStaked) {
    totalTvl = tvl
  }

  const valueProvider = useMemo(() => {
    return {
      klpPrice,
      kingPrice,
      totalKingSupplies: totalKingSupplies?.[1] || BigNumber.from(0),
      totalValueLocked: {
        total: totalTvl,
      },
      totalFees: {
        total: currentStats.totalFees,
      },
      totalUsers: {
        total: currentStats.totalUser,
      },
      totalTradingVolume: {
        total: currentStats.totalVolume,
      },
      longOpenInterest: {
        total: currentStats.longOpenInterest,
      },
      shortOpenInterest: {
        total: currentStats.shortOpenInterest,
      },
      feeSinceToNow: {
        total: currentStats.feeSinceToNow,
      },
      vol24h: {
        total: currentStats?.volume24H
      }
    };
  }, [totalTvl, currentStats]);

  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
