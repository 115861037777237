import cx from "classnames";
import { getAppBaseUrl, getHomeUrl } from "lib/legacy";
import { ReactNode } from "react";
import { NavLink, useHistory } from "react-router-dom";

import "./Header.scss";

type Props = {
  isHome?: boolean;
  isHomeLink?: boolean;
  className?: string;
  exact?: boolean;
  to: string;
  shouldShowRedirectModal?: boolean;
  showRedirectModal: (to: string) => void;
  redirectPopupTimestamp: number;
  children?: ReactNode;
};

export function HeaderLink({
  isHomeLink,
  className,
  exact,
  to,
  children,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const isOnHomePage = window.location.pathname === "/";
  const history = useHistory();
  const isHome =
  ((window.location.href.includes("landing-kingpin.vercel.app/#/")
  ||  window.location.href.includes("kingpin-interface-git-kava-homa.vercel.app/#/")
  ) && history.location.pathname === "/") ||
  (history.location.pathname === "/" && window.location.href.includes("localhost"))
    ? true
    : false;
  if (isHome && !(isHomeLink && !isOnHomePage)) {
    // if (shouldShowRedirectModal(redirectPopupTimestamp)) {
    //   return (
    //     <div className={cx("a", className, { active: isHomeLink })} onClick={() => showRedirectModal(to)}>
    //       {children}
    //     </div>
    //   );
    // } else {
    const baseUrl = getAppBaseUrl();
    return (
      <a className={cx("a", className, { active: isHomeLink })} href={baseUrl + to}>
        {children}
      </a>
    );
    // }
  }

  if (isHomeLink) {
    return (
      <a href={getHomeUrl()} className={cx(className)}>
        {children}
      </a>
    );
  }

  return (
    <NavLink activeClassName="active" className={cx(className)} exact={exact} to={to}>
      {children}
    </NavLink>
  );
}
