import noCheckImg from "img/nft/checkbox.svg";
import checkImg from "img/nft/checked.svg";
import nftImg from "img/nft/item.png";
import linkIcon from "img/nft/link.svg";
import icPower from "img/nft/power.svg";
import icEmpty from "img/nft/empty.svg";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import kavaIcon from "img/icon_kava.svg";
import Footer from "components/Footer/Footer";
import { useWeb3React } from "@web3-react/core";
import "./index.scss";
import { Pagination } from "antd";
const TYPE = {
  0: "NO_STAKE",
  1: "STAKED",
};

export default function YourNFT({ connectWallet }) {
  const { active, activate, account, library } = useWeb3React();
  const [choosenNFT, setChoosenNFT] = useState([]);
  const listNFT = [
    {
      id: 1,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 2,
      img: nftImg,
      power: 100,
      type: TYPE[1],
    },
    {
      id: 3,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 4,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 5,
      img: nftImg,
      power: 100,
      type: TYPE[1],
    },
    {
      id: 11,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 21,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 31,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 41,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
    {
      id: 51,
      img: nftImg,
      power: 100,
      type: TYPE[0],
    },
  ];
  const fullID = [];
  const ObjListNFT = {};

  listNFT.map((item) => {
    fullID.push(item.id);
    ObjListNFT[item.id] = item;
    return item;
  });

  const listIdStaked = [];
  const listIdNoStake = [];
  choosenNFT.map((item) => {
    if (ObjListNFT[item].type === TYPE[0]) listIdNoStake.push(item);
    else {
      listIdStaked.push(item);
    }

    return item;
  });

  const handleChange = (clickItem) => {
    const lists = [...choosenNFT];

    if (lists.includes(clickItem)) {
      const filterList = lists.filter((item) => {
        return item !== clickItem;
      });

      setChoosenNFT(filterList);
      return;
    } else {
      lists.push(clickItem);
      setChoosenNFT(lists);
    }
  };
  const handleSelect = (type) => {
    if (type === "add") {
      setChoosenNFT(fullID);
    } else {
      setChoosenNFT([]);
    }
  };

  return (
    <div className="container nft-wrapper">
      <div className="default-container page-layout your-nft ">
        <div className="title-nft">Earn real profit with Kingpin Club</div>
        <div className="desc">Earn $KAVA from platform's collected fees with your NFT</div>
        <div className="info">
          <div className="commonInfo">
            <div>
              <div>
                <div className="label">Current epoch</div>
                <div className="value">#0</div>
              </div>
              <div>
                <div className="label">Next epoch</div>
                <div className="value">1d : 15h : 15m : 15s</div>
              </div>
            </div>
            <div>
              <div>
                <div className="label">Staked NFT</div>
                <div className="value">100/500</div>
              </div>
              <div>
                <div className="label">Est. APR</div>
                <div className="value-apr">5,000%</div>
              </div>
            </div>
            <div>
              <div>
                <div className="label">Total distributed</div>
                <div className="value">
                  <img src={kavaIcon} alt="icon" /> 100
                </div>
              </div>

              <div>
                <div className="label">Next epoch reward</div>
                <div className="value">
                  <img src={kavaIcon} alt="icon" /> 5,000
                </div>
              </div>
            </div>
          </div>
          <div className="yourInfo">
            <div>
              <div>
                <div className="label">Your staked power</div>
                <div className="value">12</div>
              </div>
              <div>
                <div className="label">Next epoch reward</div>
                <div className="value">
                  {" "}
                  <img src={kavaIcon} alt="icon" /> 1,200{" "}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="label">Est. your APR</div>
                <div className="value-apr">2,000%</div>
              </div>

              <div>
                <div className="label">Pending reward</div>
                <div className="value">
                  <img src={kavaIcon} alt="icon" /> 100{" "}
                </div>
              </div>
            </div>

            <div className="btnWrap">
              <button className="default-btn" disabled={!account}>
                Claim
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="nft-box">
          <div>
            <div className="listTitle">
              Your NFT: <span>0</span>
            </div>{" "}
            <div className="listTitle">
              Your staked NFT: <span>0</span>
            </div>
          </div>
          <Link className="mint-more" to="nft-mint">
            Mint more NFT <img alt="link" src={linkIcon} />{" "}
          </Link>
        </div>
        <div className="joinLayout">
          {account || listNFT.length > 0 ? (
            <div className={`nft-action ${choosenNFT.length === 0 ? "d-none" : ""}`}>
              <div>
                <div>Selected: {choosenNFT.length}</div>
                <div
                  className="select-action"
                  onClick={() => {
                    handleSelect("add");
                  }}
                >
                  Select All
                </div>{" "}
                <div
                  className="select-action"
                  onClick={() => {
                    handleSelect("remove");
                  }}
                >
                  Deselect All
                </div>
              </div>

              {account ? (
                <div className="nft-action-btns">
                  {" "}
                  <button className="default-btn" disabled={listIdNoStake.length < 1}>
                    Stake {listIdNoStake.length ? "(" + listIdNoStake.length + ")" : ""}{" "}
                  </button>{" "}
                  <button className="normal-btn" disabled={listIdStaked.length < 1}>
                    Unstake {listIdStaked.length ? "(" + listIdStaked.length + ")" : ""}{" "}
                  </button>{" "}
                </div>
              ) : (
                <button className="default-btn" onClick={connectWallet}>
                  Connect wallet
                </button>
              )}
            </div>
          ) : null}

          <div className="listNFT">
            {account || listNFT.length === 0 ? (
              <EmptyContent />
            ) : (
              <div className="list">
                {listNFT.map((item) => (
                  <div key={item.id} className={choosenNFT.includes(item.id) ? "nftItem nftItem-checked" : "nftItem"}>
                    <div className="checkItem">
                      {" "}
                      <img
                        src={choosenNFT.includes(item.id) ? checkImg : noCheckImg}
                        alt="check-box"
                        onClick={() => handleChange(item.id)}
                      />{" "}
                    </div>
                    <div className="item">
                      <img src={item.img} alt="nft" className="nft-avt" />
                      <div className="nft-info">
                        <div className="nft-info-name">KING #{item.id}</div>
                        <div className="nft-info-power">
                          <img style={{ opacity: "0.6" }} alt="power" src={icPower} /> {item.power}
                        </div>
                      </div>
                      <button className={choosenNFT.includes(item.id) ? "default-btn" : " normal-btn"}>
                        {item.type === "STAKED" ? "Unstake" : "Stake"}{" "}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {listNFT.length > 0 && (
              <div className="pagination-wrapper">
                <Pagination defaultCurrent={1} total={500} showSizeChanger={false} />
              </div>
            )}
          </div>
        </div>
        <Footer className="common-footer" />{" "}
      </div>
    </div>
  );
}

const EmptyContent = () => {
  return (
    <div className="empty-nft">
      <img alt="empty" src={icEmpty} />
      <div className="empty-txt">You don’t have any NFT</div>
      <Link to="/nft-mint" className="link-mint">
        Mint now <img src={linkIcon} alt="link" />{" "}
      </Link>
    </div>
  );
};
