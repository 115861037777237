import cx from "classnames";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/logo.svg";
import { isHomeSite } from "lib/legacy";
import "./Footer.css";
import { SOCIAL_LINKS } from "./constants";
import { useHistory } from "react-router-dom";

type Props = {
  className?: string;
};

export default function Footer({ className }: Props) {
  const history = useHistory();
  const isHome =
    ((window.location.href.includes("landing-kingpin.vercel.app/#/")
    ||  window.location.href.includes("kingpin-interface-git-kava-homa.vercel.app/#/")
    ) && history.location.pathname === "/") ||
    (history.location.pathname === "/" && window.location.href.includes("localhost"))
      ? true
      : false;

  return (
    <div className={cx("Footer", { commonFooter: className })}>
      <div className={cx("Footer-wrapper", { isNotHomeFooter: !isHome })}>
        <div className="default-container footer-wrap">
          <div className="Footer-logo">
            <img src={logoImg} alt="MetaMask" />
          </div>
          <div className="Footer-social-link-block">
            {SOCIAL_LINKS.map((platform) => {
              return (
                <ExternalLink key={platform.name} className="App-social-link" href={platform.link}>
                  <img src={platform.icon} alt={platform.name} />
                </ExternalLink>
              );
            })}
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
