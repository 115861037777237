import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { isHomeSite } from "lib/legacy";

import { useWeb3React } from "@web3-react/core";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { KAVA, ARBITRUM, AVALANCHE } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getIcon } from "config/icons";

const klpIcon = getIcon("common", "klp");
const kingIcon = getIcon("common", "king");

export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();
  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={kingIcon} width="64" alt=" Icons" />
          <span> KING</span>
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            KING is the utility and governance token. Accrues 30% of the platform's generated fees.
          </div>
          <div className="Home-token-card-option-apr">
            APR:{" "}
            <span>
              <APRLabel chainId={KAVA} label="kingAprTotal" />
            </span>
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              <BuyLink to="/buy_king" className="default-btn" network={KAVA}>
                <Trans>Buy </Trans>
              </BuyLink>
              {/* <BuyLink to="/buy_king" className="default-btn" network={AVALANCHE}>
                <Trans>Buy on Avalanche</Trans>
              </BuyLink> */}
            </div>
            <ExternalLink href="https://kingio.gitbook.io/king/tokenomics" className="outline-btn read-more">
              <Trans>Read more</Trans>
            </ExternalLink>
          </div>
        </div>
      </div>
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={klpIcon} width="64" alt=" Icon" /> <span>KLP </span>
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            KLP is the liquidity provider token. Accrues 70% of the platform's generated fees.
          </div>
          <div className="Home-token-card-option-apr">
            APR:{" "}
            <span>
              <APRLabel chainId={KAVA} label="klpAprTotal" key="KAVA" />
            </span>
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              <BuyLink to="/buy_klp" className="default-btn" network={KAVA}>
                <Trans>Buy </Trans>
              </BuyLink>
              {/* <BuyLink to="/buy_klp" className="default-btn" network={AVALANCHE}>
                <Trans>Buy on Avalanche</Trans>
              </BuyLink> */}
            </div>
            <a
              href="https://kingio.gitbook.io/king/klp"
              target="_blank"
              rel="noreferrer"
              className="outline-btn read-more"
            >
              <Trans>Read more</Trans>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
