import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";
import React, { useCallback, useEffect } from "react";
import { HeaderLink } from "./HeaderLink";
import connectWalletImg from "img/ic_wallet_24.svg";

import "./Header.scss";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome";
import { KAVA, ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, AVALANCHE_FUJI, getChainName } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import { isDevelopment } from "config/env";
import { getIcon } from "config/icons";
import { useHistory } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

const NETWORK_OPTIONS = [
  {
    label: getChainName(ARBITRUM),
    value: ARBITRUM,
    icon: getIcon(ARBITRUM, "network"),
    color: "#264f79",
  },
  {
    label: getChainName(AVALANCHE),
    value: AVALANCHE,
    icon: getIcon(AVALANCHE, "network"),
    color: "#E841424D",
  },
];

if (isDevelopment()) {
  NETWORK_OPTIONS.push({
    label: getChainName(ARBITRUM_TESTNET),
    value: ARBITRUM_TESTNET,
    icon: getIcon(ARBITRUM_TESTNET, "network"),
    color: "#264f79",
  });
  NETWORK_OPTIONS.push({
    label: getChainName(AVALANCHE_FUJI),
    value: AVALANCHE_FUJI,
    icon: getIcon(AVALANCHE_FUJI, "network"),
    color: "#E841424D",
  });
}

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();
  const { active, account } = useWeb3React();
  // const showConnectionOptions = !isHomeSite();
  const history = useHistory();
  const isLanding =
    (window.location.href.includes("landing-kingpin.vercel.app/#/") && history.location.pathname === "/") ||
    (history.location.pathname === "/" && window.location.href.includes("localhost"))
      ? true
      : false;
  const showConnectionOptions = !isLanding;

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  if (isLanding) {
    return (
      <div className="App-header-user">
        <div className={cx("App-header-trade-link", { "homepage-header": isLanding })}>
          {/* {window.location.href.includes("localhost") ? (
            <HeaderLink
              className="default-btn"
              to="/trade"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              Trade
            </HeaderLink>
          ) : (
            <ExternalLink href="https://app.bfx.finance/#/trade" className="not-underline default-btn" target="_self">
              Trade
            </ExternalLink>
          )} */}

          {/* {isLanding && window.location.href.includes("localhost") && ( */}
          {isLanding && (
            <HeaderLink
              className="default-btn ml-16 btn-launch"
              to="/dashboard"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              Launch App
            </HeaderLink>
          )}
          {/* {isLanding && !window.location.href.includes("localhost") && (
            <ExternalLink href="https://app.bfx.finance/#/dashboard" className="not-underline ml-12" target="_self">
              <button className="default-btn ml-8">Launch app</button>
            </ExternalLink>
          )} */}
        </div>

        {showConnectionOptions && !isLanding ? (
          <>
            <button className="default-btn" onClick={() => setWalletModalVisible(true)}>
              Connect wallet
            </button>
            {/* <NetworkDropdown
              small={small}
              networkOptions={NETWORK_OPTIONS}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            /> */}
          </>
        ) : null}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);
  if (account)
    return (
      <div className="App-header-user">
        <div className="App-header-trade-link">
          <HeaderLink
            className="default-btn"
            to="/trade"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Trade</Trans>
          </HeaderLink>
        </div>

        {showConnectionOptions ? (
          <>
            <div className="App-header-user-address ml-16">
              <AddressDropdown
                account={account}
                accountUrl={accountUrl}
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              />
            </div>
            <NetworkDropdown
              small={small}
              networkOptions={NETWORK_OPTIONS}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            />
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    );
  return (
    <button className="default-btn" onClick={() => setWalletModalVisible(true)}>
      Connect wallet
    </button>
  );
}
