import { KAVA, ARBITRUM, AVALANCHE, ETH_MAINNET } from "./chains";
import { isDevelopment } from "./env";
import { getSubgraphUrlKey } from "./localStorage";

const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-stats",
    referrals: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/king-vault",
  },

  [KAVA]: {
    stats: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-stats",
    referrals: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-referrals",
  },

  [AVALANCHE]: {
    stats: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-stats",
    referrals: "https://the-graph.kava.io/subgraphs/name/kingpin-kava-referrals",
  },

  [ETH_MAINNET]: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};

export function getSubgraphUrl(chainId: number, subgraph: string) {
  if (isDevelopment()) {
    const localStorageKey = getSubgraphUrlKey(chainId, subgraph);
    const url = localStorage.getItem(localStorageKey);
    if (url) {
      // eslint-disable-next-line no-console
      console.warn("%s subgraph on chain %s url is overriden: %s", subgraph, chainId, url);
      return url;
    }
  }

  return SUBGRAPH_URLS[chainId][subgraph];
}
