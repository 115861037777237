import { useWeb3React } from "@web3-react/core";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Footer from "components/Footer/Footer";
import MaskIcon from "img/nft_mask.svg";
import ScanIcon from "img/red_scan.svg";
import { useMedia } from "react-use";
import { Area, AreaChart, ResponsiveContainer, XAxis } from "recharts";
import "./Mint.scss";
import { useState } from "react";
import MintModal from "./MintModal";
export default function Mint({ connectWallet, setPendingTxns, pendingTxns }) {
  const { account } = useWeb3React();
  const [isVisible, setIsVisible] = useState(false);
  let dataFinal = [];
  const HOW_TO = [
    {
      name: "Mint NFT",
      desc: "The earlier you mint, the cheaper the NFT and the higher the power to earn rewards.",
      link: "",
      linkContent: "Mint now",
    },
    {
      name: "Stake",
      desc: "Stake your NFT to earn rewards from Community Treasury.",
      link: "",
      linkContent: "Stake now",
    },
    {
      name: "Earn",
      desc: "Earn rewards in $KAVA. The longer you stake, the higher rewards you earn from Kingpin Finance.",
      link: "",
      linkContent: "See your reward",
    },
  ];
  const isSmallerScreen = useMedia("(max-width: 1023px)");
  return (
    <div>
      <MintModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        connectWallet={connectWallet}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
      />
      <div className="mint-wrapper">
        <div className="common-mint-layout mint-top-banner">
          <div className="nft-left-info">
            <div className="title">Kingpin Club</div>
            <div className="desc">
              A collection of 5,000 Kingpin NFT on KAVA, creating a community centered around strong identity, values,
              and rewards
            </div>
            <div className="buttons-actions">
              <button
                className="default-btn"
                onClick={() => {
                  setIsVisible(true);
                }}
              >
                Mint NFT
              </button>
              <button className="outline-btn">Trade on Opensea</button>
            </div>
            <div className="mint-stats">
              <div>
                <label>Total volume</label>
                <div className="mint-stats-amount">$131,850</div>
              </div>
              <div>
                <label>NFT minted</label>
                <div className="mint-stats-amount">131</div>
              </div>
              <div>
                <label>NFT supply</label>
                <div className="mint-stats-amount">131,850</div>
              </div>
            </div>
          </div>
          <div className="nft-right-content">
            <img alt="" src={MaskIcon} />
          </div>
        </div>
        <div className="treasury-container">
          <div className="common-mint-layout treasury-content">
            <div className="title">Treasury</div>
            <div className="desc">Profits from Treasury yield will be distributed to all NFT holders</div>
            <div className="chart-wrapper">
              <div className="stats">
                <div className="stats-line">
                  <div className="label total-stake-label">Total staked</div>
                  <div className="amount-box">
                    {/* <Tooltip
                      handle={`$--`}
                      position={`${isSmallerScreen ? "right-bottom" : "left-bottom"}`}
                      className="amount--large"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow label={`Staked KLP`} value={`--`} showDollar={true} />
                            <StatsTooltipRow label={`Staked esKING`} value={`--`} showDollar={true} />
                          </>
                        );
                      }}
                    /> */}
                    <div className="value-amount">
                      150,000,000 KAVA <span className="value-amount-usd">$150,000,000</span>
                    </div>
                  </div>
                </div>
                <div className="stats-right">
                  <div className="stats-line">
                    <div className="label">Compounding Reward</div>
                    <div className="amount">-- esKING</div>
                  </div>{" "}
                  <div className="pending-box stats-line">
                    <div className="label">Pending Reward</div>
                    <div className="amount">
                      <span>--</span> esKING + <span>-- </span>KAVA
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart">
                <ResponsiveContainer height={205} width="100%">
                  <AreaChart data={dataFinal}>
                    <XAxis dataKey="name" />
                    <Area type="monotone" dataKey="uv" stroke=" #95DE64" fill="rgba(149, 222, 100, 0.1)" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "140%",
                }}
              >
                <span style={{ color: "#E24647" }}>Note: </span>Charts will be shown when data is available.
              </div>
            </div>
          </div>
        </div>
        <div className="common-mint-layout how-to-container">
          <div className="title">How to get reward with Kingpin Club</div>
          <div className="desc">Very simple, you just need to hold Kingpin NFT</div>
          <div className="how-to-step-container">
            {HOW_TO.map((item, index) => (
              <div className="step-content" key={index}>
                <div className="step-title">{item.name}</div>
                <div className="step-desc">{item.desc}</div>
                <div className="step-link">
                  <ExternalLink href={item.link}>
                    {item.linkContent} <img alt="" src={ScanIcon} />
                  </ExternalLink>
                  <div className="step">{index + 1}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
