import { KAVA, ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, AVALANCHE_FUJI } from "config/chains";
import arbitrum from "img/ic_arbitrum_24.svg";
import kava from "img/icon_kava.svg";
import avalanche from "img/ic_avalanche_24.svg";
import avalancheTestnet from "img/ic_avalanche_testnet_24.svg";

import kingIcon from "img/ic_king.svg";
import klpIcon from "img/ic_klp.svg";
const ICONS = {
  [ARBITRUM]: {
    network: arbitrum,
  },
  [KAVA]: {
    network: kava,
    king: kingIcon,
    klp: klpIcon,
  },
  [AVALANCHE]: {
    network: avalanche,
  },
  [ARBITRUM_TESTNET]: {
    network: arbitrum,
  },
  [AVALANCHE_FUJI]: {
    network: avalancheTestnet,
  },
  common: {
    king: kingIcon,
    klp: klpIcon,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
