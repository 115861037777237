import React, { useState, useCallback } from "react";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import Modal from "components/Modal/Modal";
import Checkbox from "components/Checkbox/Checkbox";
import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import KlpManager from "abis/KlpManager.json";

import { ethers } from "ethers";
import {
  KLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "lib/legacy";
import { useKingPrice, useTotalKingStaked, useTotalKingSupply } from "domain/legacy";
import { ARBITRUM, getChainName, getConstant } from "config/chains";

import useSWR from "swr";

import { getContract } from "config/contracts";

import "./StakeV2.scss";
import SEO from "components/Common/SEO";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getServerUrl } from "config/backend";
import { callContract, contractFetcher } from "lib/contracts";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { approveTokens } from "domain/tokens";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import KINGAprTooltip from "components/Stake/KINGAprTooltip";
import Button from "components/Button/Button";
import { Link } from "react-router-dom";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Stake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="w-100 default-btn " onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusKingInFeeKing,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusKingInFeeKing &&
    bonusKingInFeeKing.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusKingInFeeKing);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusKingInFeeKing) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusKingInFeeKing);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Unstake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink className="display-inline" href="https://kingio.gitbook.io/king/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="w-100 default-btn" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed!`,
      successMsg: `Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">Deposit</div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                Max: {formatAmount(maxAmount, 18, 4, true)}
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esKING</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Wallet</div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esKING</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Vault Capacity</div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">Vault Capacity for your Account:</p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esKING`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esKING`}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Reserve Amount</div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={t`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Additional reserve required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                            {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esKING.
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="w-100 default-btn" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div>
          This will withdraw and unreserve all tokens as well as pause vesting.
          <br />
          <br />
          esKING tokens that have been converted to KING will remain as KING tokens.
          <br />
          <br />
          To claim KING tokens without withdrawing, use the "Claim" button under the Total Rewards section.
          <br />
          <br />
        </div>

        <div className="Exchange-swap-button-container">
          <button className="w-100 default-btn" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  const [shouldClaimKing, setShouldClaimKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-king"],
    true
  );
  const [shouldStakeKing, setShouldStakeKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-king"],
    true
  );
  const [shouldClaimEsKing, setShouldClaimEsKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-king"],
    true
  );
  const [shouldStakeEsKing, setShouldStakeEsKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-king"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const kingAddress = getContract(chainId, "KING");
  const stakedKingTrackerAddress = getContract(chainId, "StakedKingTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, kingAddress, "allowance", account, stakedKingTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeKing && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving KING...`;
    }
    if (needApproval) {
      return `Approve KING`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: kingAddress,
        spender: stakedKingTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimKing || shouldStakeKing,
        shouldStakeKing,
        shouldClaimEsKing || shouldStakeEsKing,
        shouldStakeEsKing,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeKing = (value) => {
    if (value) {
      setShouldClaimKing(true);
    }
    setShouldStakeKing(value);
  };

  const toggleShouldStakeEsKing = (value) => {
    if (value) {
      setShouldClaimEsKing(true);
    }
    setShouldStakeEsKing(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Compound Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              Stake Multiplier Points
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimKing} setIsChecked={setShouldClaimKing} disabled={shouldStakeKing}>
              Claim KING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeKing} setIsChecked={toggleShouldStakeKing}>
              Stake KING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsKing} setIsChecked={setShouldClaimEsKing} disabled={shouldStakeEsKing}>
              Claim esKING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsKing} setIsChecked={toggleShouldStakeEsKing}>
              Stake esKING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              Claim {wrappedTokenSymbol} Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="w-100 default-btn" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimKing, setShouldClaimKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-king"],
    true
  );
  const [shouldClaimEsKing, setShouldClaimEsKing] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-king"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimKing,
        false, // shouldStakeKing
        shouldClaimEsKing,
        false, // shouldStakeEsKing
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={t`Claim Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimKing} setIsChecked={setShouldClaimKing}>
              Claim KING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsKing} setIsChecked={setShouldClaimEsKing}>
              Claim esKING Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              Claim {wrappedTokenSymbol} Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="w-100 default-btn" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const hasInsurance = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const kingAddress = getContract(chainId, "KING");
  const esKingAddress = getContract(chainId, "ES_KING");
  const bnKingAddress = getContract(chainId, "BN_KING");
  const klpAddress = getContract(chainId, "KLP");

  const stakedKingTrackerAddress = getContract(chainId, "StakedKingTracker");
  const bonusKingTrackerAddress = getContract(chainId, "BonusKingTracker");
  const feeKingTrackerAddress = getContract(chainId, "FeeKingTracker");

  const stakedKlpTrackerAddress = getContract(chainId, "StakedKlpTracker");
  const feeKlpTrackerAddress = getContract(chainId, "FeeKlpTracker");

  const klpManagerAddress = getContract(chainId, "KlpManager");

  const stakedKingDistributorAddress = getContract(chainId, "StakedKingDistributor");
  const stakedKlpDistributorAddress = getContract(chainId, "StakedKlpDistributor");

  const kingVesterAddress = getContract(chainId, "KingVester");
  const klpVesterAddress = getContract(chainId, "KlpVester");

  const vesterAddresses = [kingVesterAddress, klpVesterAddress];

  const excludedEsKingAccounts = [stakedKingDistributorAddress, stakedKlpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [kingAddress, esKingAddress, klpAddress, stakedKingTrackerAddress];
  const depositTokens = [
    kingAddress,
    esKingAddress,
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    bnKingAddress,
    klpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedKingTrackerAddress,
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    feeKingTrackerAddress,
    feeKingTrackerAddress,
    feeKlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    feeKingTrackerAddress,
    stakedKlpTrackerAddress,
    feeKlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedKingSupply } = useSWR(
    [`StakeV2:stakedKingSupply:${active}`, chainId, kingAddress, "balanceOf", stakedKingTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, klpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, KlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
    }
  );

  const { data: esKingSupply } = useSWR(
    [`StakeV2:esKingSupply:${active}`, chainId, readerAddress, "getTokenSupply", esKingAddress],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsKingAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  const { kingPrice, kingPriceFromArbitrum, kingPriceFromAvalanche } = useKingPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined },
    active
  );

  let { total: totalKingSupply } = useTotalKingSupply();

  let { avax: avaxKingStaked, arbitrum: arbitrumKingStaked, total: totalKingStaked } = useTotalKingStaked();

  const kingSupplyUrl = getServerUrl(chainId, "/king_supply");
  const { data: kingSupply } = useSWR([kingSupplyUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  const isKingTransferEnabled = true;

  let esKingSupplyUsd;
  if (esKingSupply && kingPrice) {
    esKingSupplyUsd = esKingSupply.mul(kingPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedKingSupply,
    kingPrice,
    kingSupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusKingTrackerRewards && processedData.bnKingInFeeKing) {
    multiplierPointsAmount = processedData.bonusKingTrackerRewards.add(processedData.bnKingInFeeKing);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnKingInFeeKing && processedData.bonusKingInFeeKing) {
    totalRewardTokens = processedData.bnKingInFeeKing.add(processedData.bonusKingInFeeKing);
  }

  let totalRewardTokensAndKlp;
  if (totalRewardTokens && processedData && processedData.klpBalance) {
    totalRewardTokensAndKlp = totalRewardTokens.add(processedData.klpBalance);
  }

  const bonusKingInFeeKing = processedData ? processedData.bonusKingInFeeKing : undefined;

  let stakedKingSupplyUsd;
  if (!totalKingStaked.isZero() && kingPrice) {
    stakedKingSupplyUsd = totalKingStaked.mul(kingPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalKingSupply && !totalKingSupply.isZero() && kingPrice) {
    totalSupplyUsd = totalKingSupply.mul(kingPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableKing = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.kingVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusKingInFeeKing
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.kingVesterPairAmount);
    const stakedTokens = processedData.bonusKingInFeeKing;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableKing = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const showStakeKingModal = () => {
    if (!isKingTransferEnabled) {
      helperToast.error(t`KING transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(t`Stake KING`);
    setStakeModalMaxAmount(processedData.kingBalance);
    setStakeValue("");
    setStakingTokenSymbol("KING");
    setStakingTokenAddress(kingAddress);
    setStakingFarmAddress(stakedKingTrackerAddress);
    setStakeMethodName("stakeKing");
  };

  const showStakeEsKingModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esKING`);
    setStakeModalMaxAmount(processedData.esKingBalance);
    setStakeValue("");
    setStakingTokenSymbol("esKING");
    setStakingTokenAddress(esKingAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsKing");
  };

  const showKingVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.kingVester.maxVestableAmount.sub(vestingData.kingVester.vestedAmount);
    if (processedData?.esKingBalance?.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esKingBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(`KING Vault`);
    setVesterDepositStakeTokenLabel("staked KING + esKING + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esKingBalance);
    setVesterDepositEscrowedBalance(vestingData.kingVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.kingVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.kingVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.kingVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.kingVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(kingVesterAddress);
  };

  const showKlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.klpVester.maxVestableAmount.sub(vestingData.klpVester.vestedAmount);
    if (processedData.esKingBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esKingBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(`KLP Vault`);
    setVesterDepositStakeTokenLabel("staked KLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esKingBalance);
    setVesterDepositEscrowedBalance(vestingData.klpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.klpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.klpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.klpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.klpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.klpBalance);
    setVesterDepositValue("");
    setVesterDepositAddress(klpVesterAddress);
  };

  const showKingVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.kingVesterVestedAmount || vestingData.kingVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from KING Vault`);
    setVesterWithdrawAddress(kingVesterAddress);
  };

  const showKlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.klpVesterVestedAmount || vestingData.klpVesterVestedAmount.eq(0)) {
      helperToast.error(t`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(t`Withdraw from KLP Vault`);
    setVesterWithdrawAddress(klpVesterAddress);
  };

  const showUnstakeKingModal = () => {
    if (!isKingTransferEnabled) {
      helperToast.error(t`KING transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake KING");
    let maxAmount = processedData.kingInStakedKing;
    if (
      processedData.kingInStakedKing &&
      vestingData &&
      vestingData.kingVesterPairAmount.gt(0) &&
      maxUnstakeableKing &&
      maxUnstakeableKing.lt(processedData.kingInStakedKing)
    ) {
      maxAmount = maxUnstakeableKing;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.kingVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("KING");
    setUnstakeMethodName("unstakeKing");
  };

  const showUnstakeEsKingModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(t`Unstake esKING`);
    let maxAmount = processedData.esKingInStakedKing;
    if (
      processedData.esKingInStakedKing &&
      vestingData &&
      vestingData.kingVesterPairAmount.gt(0) &&
      maxUnstakeableKing &&
      maxUnstakeableKing.lt(processedData.esKingInStakedKing)
    ) {
      maxAmount = maxUnstakeableKing;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.kingVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esKING");
    setUnstakeMethodName("unstakeEsKing");
  };

  const renderMultiplierPointsLabel = useCallback(() => {
    return `Multiplier Points APR`;
  }, []);

  const renderMultiplierPointsValue = useCallback(() => {
    return (
      <Tooltip
        handle={`100.00%`}
        position="right-bottom"
        renderContent={() => {
          return (
            <div>
              Boost your rewards with Multiplier Points.&nbsp;
              <ExternalLink href="/#">More info</ExternalLink>.
            </div>
          );
        }}
      />
    );
  }, []);

  let earnMsg;
  if (totalRewardTokensAndKlp && totalRewardTokensAndKlp.gt(0)) {
    let kingAmountStr;
    if (processedData.kingInStakedKing && processedData.kingInStakedKing.gt(0)) {
      kingAmountStr = formatAmount(processedData.kingInStakedKing, 18, 2, true) + " KING";
    }
    let esKingAmountStr;
    if (processedData.esKingInStakedKing && processedData.esKingInStakedKing.gt(0)) {
      esKingAmountStr = formatAmount(processedData.esKingInStakedKing, 18, 2, true) + " esKING";
    }
    let mpAmountStr;
    if (processedData.bonusKingInFeeKing && processedData.bnKingInFeeKing.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnKingInFeeKing, 18, 2, true) + " MP";
    }
    let klpStr;
    if (processedData.klpBalance && processedData.klpBalance.gt(0)) {
      klpStr = formatAmount(processedData.klpBalance, 18, 2, true) + " KLP";
    }
    const amountStr = [kingAmountStr, esKingAmountStr, mpAmountStr, klpStr].filter((s) => s).join(", ");
    earnMsg = (
      <>
        <div className="stats-des">
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndKlp, 18, 2, true)} tokens.
        </div>
        <div className="stats-des">Tokens: {amountStr}.</div>
      </>
    );
  }

  return (
    // <div className="common-layout ">
    //   <div className="common-layout-top ">
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusKingInFeeKing={bonusKingInFeeKing}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <div className="section-title-block">
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div className="Page-title">Earn</div>
          <div className="Page-description">
            <div className="stats-des">
              Stake <ExternalLink href="/#">KING</ExternalLink> and <ExternalLink href="/#">KLP</ExternalLink> to earn
              rewards.
            </div>
            {earnMsg && { earnMsg }}
          </div>
        </div>
      </div>
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="App-card ">
            <div className="App-card-title">KING</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>
                  {!kingPrice && "..."}
                  {/* {kingPrice && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={"$" + formatAmount(kingPrice, USD_DECIMALS, 2, true)}
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={t`Price on Kava`}
                            value={formatAmount(kingPriceFromAvalanche, USD_DECIMALS, 2, true)}
                          />
                        </>
                      )}
                    />
                  )} */}{" "}
                  {"$" + formatAmount(kingPrice, USD_DECIMALS, 2, true)}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "kingBalance", 18, 2, true)} KING ($
                  {formatKeyAmount(processedData, "kingBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "kingInStakedKing", 18, 2, true)} KING ($
                  {formatKeyAmount(processedData, "kingInStakedKingUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "kingAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <KINGAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalKingRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol})`}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeKingTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(processedData, "feeKingTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label="Escrowed KING"
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedKingTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(
                              processedData,
                              "stakedKingTrackerRewardsUsd",
                              USD_DECIMALS,
                              2,
                              true
                            )})`}
                            showDollar={false}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Boost Percentage</div>
                <div>
                  <Tooltip
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <div>
                          You are earning {formatAmount(processedData.boostBasisPoints, 2, 2, false)}% more{" "}
                          {nativeTokenSymbol} rewards using{" "}
                          {formatAmount(processedData.bnKingInFeeKing, 18, 4, 2, true)} Staked Multiplier Points.
                          <br />
                          <br />
                          Use the "Compound" button to stake your Multiplier Points.
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {!totalKingStaked && "..."}
                  {/* {totalKingStaked && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        formatAmount(totalKingStaked, 18, 0, true) +
                        " KING" +
                        ` ($${formatAmount(stakedKingSupplyUsd, USD_DECIMALS, 0, true)})`
                      }
                      renderContent={() => (
                        <StatsTooltip
                          showDollar={false}
                          title={t`Staked`}
                          avaxValue={avaxKingStaked}
                          arbitrumValue={arbitrumKingStaked}
                          total={totalKingStaked}
                          decimalsForConversion={18}
                          symbol="KING"
                        />
                      )}
                    />
                  )} */}
                  {formatAmount(totalKingStaked, 18, 0, true) +
                    " KING" +
                    ` ($${formatAmount(stakedKingSupplyUsd, USD_DECIMALS, 0, true)})`}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                {!totalKingSupply && <div>...</div>}
                {totalKingSupply && (
                  <div>
                    {formatAmount(totalKingSupply, 18, 0, true)} KING ($
                    {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
                  </div>
                )}
              </div>
              <div className="App-card-divider" />
              <div className="App-card-buttons ">
                <Link className="default-btn" to="/buy_king">
                  Buy KING
                </Link>
                {active && (
                  <butotn className="default-btn" onClick={() => showStakeKingModal()}>
                    Stake
                  </butotn>
                )}
                {active && (
                  <button className="outline-btn" onClick={() => showUnstakeKingModal()}>
                    Unstake
                  </button>
                )}
                {active && (
                  <Link className="outline-btn" to="/begin_account_transfer">
                    Transfer Account
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="App-card primary StakeV2-total-rewards-card">
            <div className="App-card-title">Total Rewards</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">KAVA (WKAVA)</div>
                <div>
                  {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalNativeTokenRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">KING</div>
                <div>
                  {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Escrowed KING</div>
                <div>
                  {formatKeyAmount(processedData, "totalEsKingRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalEsKingRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Multiplier Points</div>
                <div>{formatKeyAmount(processedData, "bonusKingTrackerRewards", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked Multiplier Points</div>
                <div>{formatKeyAmount(processedData, "bnKingInFeeKing", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Total</div>
                <div>${formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="total-reward-footer">
                <div className="App-card-divider"></div>
                <div className="App-card-buttons ">
                  {active && (
                    <button className="default-btn" onClick={() => setIsCompoundModalVisible(true)}>
                      Compound
                    </button>
                  )}
                  {active && (
                    <button className="default-btn" onClick={() => setIsClaimModalVisible(true)}>
                      Claim
                    </button>
                  )}
                  {!active && (
                    <button className="default-btn" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">KLP </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatKeyAmount(processedData, "klpPrice", USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "klpBalance", KLP_DECIMALS, 2, true)} KLP ($
                  {formatKeyAmount(processedData, "klpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "klpBalance", KLP_DECIMALS, 2, true)} KLP ($
                  {formatKeyAmount(processedData, "klpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "klpAprTotal", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                            value={`${formatKeyAmount(processedData, "klpAprForNativeToken", 2, 2, true)}%`}
                            showDollar={false}
                          />
                          {processedData?.klpAprForEsKing.gt(0) && (
                            <StatsTooltipRow
                              label="Escrowed KING APR"
                              value={`${formatKeyAmount(processedData, "klpAprForEsKing", 2, 2, true)}%`}
                              showDollar={false}
                            />
                          )}
                          <br />
                          APRs are updated weekly on Wednesday and will depend on the fees collected for the week.{" "}
                          <br />
                          <br />
                          Historical KLP APRs can be checked in this{" "}
                          <ExternalLink href="/#">community dashboard</ExternalLink>.
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalKlpRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol})`}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeKlpTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(processedData, "feeKlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label="Escrowed KING"
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedKlpTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(
                              processedData,
                              "stakedKlpTrackerRewardsUsd",
                              USD_DECIMALS,
                              2,
                              true
                            )})`}
                            showDollar={false}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {formatKeyAmount(processedData, "klpSupply", 18, 2, true)} KLP ($
                  {formatKeyAmount(processedData, "klpSupplyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                <div>
                  {formatKeyAmount(processedData, "klpSupply", 18, 2, true)} KLP ($
                  {formatKeyAmount(processedData, "klpSupplyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-buttons ">
                <Link className="default-btn" to="/buy_klp">
                  Buy KLP
                </Link>
                <Link className="default-btn" to="/buy_klp#redeem">
                  Sell KLP
                </Link>
                {/* {hasInsurance && (
                  <Button
                    variant="semi-clear"
                    to="https://app.insurace.io/Insurance/Cart?id=124&referrer=545066382753150189457177837072918687520318754040"
                  >
                    Purchase Insurance
                  </Button>
                )} */}
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">Escrowed KING</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatAmount(kingPrice, USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatKeyAmount(processedData, "esKingBalance", 18, 2, true)} esKING ($
                  {formatKeyAmount(processedData, "esKingBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Staked</div>
                <div>
                  {formatKeyAmount(processedData, "esKingInStakedKing", 18, 2, true)} esKING ($
                  {formatKeyAmount(processedData, "esKingInStakedKingUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "kingAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <KINGAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {formatKeyAmount(processedData, "stakedEsKingSupply", 18, 0, true)} esKING ($
                  {formatKeyAmount(processedData, "stakedEsKingSupplyUsd", USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Total Supply</div>
                <div>
                  {formatAmount(esKingSupply, 18, 0, true)} esKING ($
                  {formatAmount(esKingSupplyUsd, USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-buttons ">
                {active && (
                  <button className="default-btn" onClick={() => showStakeEsKingModal()}>
                    Stake
                  </button>
                )}
                {active && (
                  <button className="outline-btn " onClick={() => showUnstakeEsKingModal()}>
                    Unstake
                  </button>
                )}
                {!active && (
                  <button className="default-btn" variant="semi-clear" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="Tab-title-section stake-tab-title-section">
          <div className="Page-title">Vest</div>
          <div className="Page-description">
            Convert esKING tokens to KING tokens.
            <br />
            Please read the <ExternalLink href="/#">vesting details</ExternalLink> before using the vaults.
          </div>
        </div>
        <div>
          <div className="StakeV2-cards">
            <div className="App-card ">
              <div className="App-card-title">KING Vault</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Staked Tokens</div>
                  <div>
                    <Tooltip
                      handle={formatAmount(totalRewardTokens, 18, 2, true)}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label="KING"
                              value={formatAmount(processedData.kingInStakedKing, 18, 2, true)}
                            />

                            <StatsTooltipRow
                              showDollar={false}
                              label="esKING"
                              value={formatAmount(processedData.esKingInStakedKing, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnKingInFeeKing, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Reserved for Vesting</div>
                  <div>
                    {formatKeyAmount(vestingData, "kingVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(totalRewardTokens, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Vesting Status</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "kingVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "kingVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            {formatKeyAmount(vestingData, "kingVesterClaimSum", 18, 4, true)} tokens have been converted
                            to KING from the {formatKeyAmount(vestingData, "kingVesterVestedAmount", 18, 4, true)}{" "}
                            esKING deposited for vesting.
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Claimable</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "kingVesterClaimable", 18, 4, true)} KING`}
                      position="right-bottom"
                      renderContent={() => (
                        <div>
                          {formatKeyAmount(vestingData, "kingVesterClaimable", 18, 4, true)} KING tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-buttons ">
                  {!active && (
                    <button className="default-btn" variant="semi-clear" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                  {active && (
                    <button className="default-btn" onClick={() => showKingVesterDepositModal()}>
                      Deposit
                    </button>
                  )}
                  {active && (
                    <button className="outline-btn" onClick={() => showKingVesterWithdrawModal()}>
                      Withdraw
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="App-card ">
              <div className="App-card-title">KLP Vault</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Staked Tokens</div>
                  <div>{formatAmount(processedData.klpBalance, 18, 2, true)} KLP</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Reserved for Vesting</div>
                  <div>
                    {formatKeyAmount(vestingData, "klpVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(processedData.klpBalance, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Vesting Status</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "klpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "klpVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            {formatKeyAmount(vestingData, "klpVesterClaimSum", 18, 4, true)} tokens have been converted
                            to KING from the {formatKeyAmount(vestingData, "klpVesterVestedAmount", 18, 4, true)} esKING
                            deposited for vesting.
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">Claimable</div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "klpVesterClaimable", 18, 4, true)} KING`}
                      position="right-bottom"
                      renderContent={() => (
                        <div>
                          {formatKeyAmount(vestingData, "klpVesterClaimable", 18, 4, true)} KING tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-buttons">
                  {!active && (
                    <button className="default-btn" variant="semi-clear" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                  {active && (
                    <button className="default-btn" onClick={() => showKlpVesterDepositModal()}>
                      Deposit
                    </button>
                  )}
                  {active && (
                    <button className="outline-btn" onClick={() => showKlpVesterWithdrawModal()}>
                      Withdraw
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
      <Footer className="common-footer" />
    </div>
  );
}
