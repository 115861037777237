import "./Referrals.css";
import React, { useMemo } from "react";
import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from "domain/referrals";
import JoinReferralCode from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode from "components/Referrals/AddAffiliateCode";
import {
  deserializeSampleStats,
  getUSDValue,
  isRecentReferralCodeNotExpired,
} from "components/Referrals/referralsHelper";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";


import "./Referrals.scss";
import InfoCard from "components/Referrals/InfoCard";
import { bigNumberify } from "lib/numbers";

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
const TAB_OPTIONS = [TRADERS, AFFILIATES];

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const { chainId } = useChainId();
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData1, loading } = useReferralsData(chainId, account);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });
  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account);
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: t`Referral code submitted!`,
      failMsg: t`Referral code creation failed.`,
      pendingTxns,
    });
  }

  const referralsData = {
    rebateDistributions: [
      {
        timestamp: 1681456384,
        transactionHash: "0x38d81112cc0bf8c595d52e1a64f7900b2bdb9f4d5a8d5c4b2cb31f5bc68703a2",
        receiver: "0xC137B04B2Ecc12169151e0A1e537f71a02d80dc3",
        amount: {
          type: "BigNumber",
          hex: "0x03e8ba463678828d",
        },
        typeId: "1",
        token: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      },
    ],
    discountDistributions: [
      {
        timestamp: 1681456392,
        transactionHash: "0x22cb37576bc623e4f56402a08219b1a1f0ade27e27e6f19a2533bda8187a6b33",
        receiver: "0xC137B04B2Ecc12169151e0A1e537f71a02d80dc3",
        amount: {
          type: "BigNumber",
          hex: "0x042487292e311f30",
        },
        typeId: "2",
        token: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      },
      {
        timestamp: 1675137962,
        transactionHash: "0x85caab48524e9fe503051e9212cea0e79bd1e0c0195720fd5f1f92fe5dcd27b2",
        receiver: "0xC137B04B2Ecc12169151e0A1e537f71a02d80dc3",
        amount: {
          type: "BigNumber",
          hex: "0x02486b6701166356",
        },
        typeId: "2",
        token: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      },
    ],
    referrerTotalStats: [
      {
        volume: {
          type: "BigNumber",
          hex: "0xdd37c24e3f37adac5a6da2a7e7a0",
        },
        trades: 53,
        tradedReferralsCount: 3,
        registeredReferralsCount: 3,
        totalRebateUsd: {
          type: "BigNumber",
          hex: "0x05a9c5e554c4651c9421efaf1a",
        },
        discountUsd: {
          type: "BigNumber",
          hex: "0x02d4e2f2aa62328e4a10f7d78d",
        },
        referralCode: "Janht",
      },
    ],
    referrerTierInfo: {
      __typename: "Referrer",
      tierId: "0",
      id: "0xc137b04b2ecc12169151e0a1e537f71a02d80dc3",
      discountShare: "0",
    },
    referrerLastDayStats: [],
    cumulativeStats: {
      totalRebateUsd: {
        type: "BigNumber",
        hex: "0x05a9c5e554c4651c9421efaf1a",
      },
      volume: {
        type: "BigNumber",
        hex: "0xdd37c24e3f37adac5a6da2a7e7a0",
      },
      discountUsd: {
        type: "BigNumber",
        hex: "0x02d4e2f2aa62328e4a10f7d78d",
      },
      trades: 53,
      tradedReferralsCount: 3,
      registeredReferralsCount: 3,
    },
    codes: ["Janht"],
    referralTotalStats: {
      volume: {
        type: "BigNumber",
        hex: "0x9c022c75d148cce239f54a4b3c40",
      },
      discountUsd: {
        type: "BigNumber",
        hex: "0x01ff356851527136ea36bfd316",
      },
    },
  };

  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;

    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    const { cumulativeStats } = referralsData || {
      cumulativeStats: {},
      referrerTotalStats: [],
      rebateDistributions: [],
      referrerTierInfo: {},
    };

    let referrerRebates = bigNumberify(0);
    if (cumulativeStats && cumulativeStats?.totalRebateUsd && cumulativeStats?.discountUsd) {
      // referrerRebates = cumulativeStats?.totalRebateUsd?.sub(cumulativeStats?.discountUsd); remember
    }

    return (
      <div className="container-ref">
        <div className="content-ref">
          <div className="left-content">
            <AddAffiliateCode
              isReferralCodeAvailable={isReferralCodeAvailable}
              referralsData={referralsData}
              handleCreateReferralCode={handleCreateReferralCode}
              active={active}
              connectWallet={connectWallet}
              recentlyAddedCodes={recentlyAddedCodes}
              setRecentlyAddedCodes={setRecentlyAddedCodes}
            />

            <div className="referral-stats custom">
              <InfoCard
                label={t`Total Traders Referred`}
                tooltipText={t`Amount of traders you referred.`}
                data={cumulativeStats?.registeredReferralsCount || "0"}
              />
              <InfoCard
                label={t`Total Trading Volume`}
                tooltipText={t`Volume traded by your referred traders.`}
                data={getUSDValue(cumulativeStats?.volume)}
              />
              <InfoCard
                label={t`Total Rebates`}
                tooltipText={t`Rebates earned by this account as an affiliate.`}
                data={getUSDValue(referrerRebates, 4)}
              />
            </div>
          </div>

          <AffiliatesStats
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
            recentlyAddedCodes={recentlyAddedCodes}
            chainId={chainId}
          />
        </div>
      </div>
    );

    // if (loading) return <Loader />;
    // if (isReferralCodeAvailable) {
    //   return (
    //     <AffiliatesStats
    //       referralsData={referralsData}
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       chainId={chainId}
    //     />
    //   );
    // } else {
    //   return (
    //     <AddAffiliateCode
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       active={active}
    //       connectWallet={connectWallet}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //     />
    //   );
    // }
  }

  function renderTradersTab() {
    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <div className="left-content">
            <JoinReferralCode
              userReferralCodeString={userReferralCodeString}
              connectWallet={connectWallet}
              active={active}
              setPendingTxns={setPendingTxns}
              pendingTxns={pendingTxns}
            />

            <div className="referral-stats">
              <InfoCard
                label={t`Total Trading Volume`}
                tooltipText={t`Volume traded by this account with an active referral code.`}
                data={getUSDValue(referralsData?.referralTotalStats?.volume)}
              />
              <InfoCard
                label={t`Total Rebates`}
                tooltipText={t`Rebates earned by this account as a trader.`}
                data={getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
              />
            </div>
          </div>

          <TradersStats
            userReferralCodeString={userReferralCodeString}
            chainId={chainId}
            referralsData={referralsData}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            traderTier={traderTier}
          />
        </div>
      </div>
    );

    // if (isHashZero(userReferralCode) || !account || !userReferralCode) {
    //   return (
    //     <JoinReferralCode
    //       connectWallet={connectWallet}
    //       active={active}
    //       setPendingTxns={setPendingTxns}
    //       pendingTxns={pendingTxns}
    //     />
    //   );
    // }
    // return (
    //   <TradersStats
    //     userReferralCodeString={userReferralCodeString}
    //     chainId={chainId}
    //     referralsData={referralsData}
    //     setPendingTxns={setPendingTxns}
    //     pendingTxns={pendingTxns}
    //     traderTier={traderTier}
    //   />
    // );
  }
  const TAB_OPTION_LABELS = { [TRADERS]: t`Traders`, [AFFILIATES]: t`Affiliates` };

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="default-container page-layout Referrals">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="left">
              <div className="Page-title">
                <Trans>Referrals</Trans>
              </div>
              <div className="Page-description">
                <Trans>
                  Get fee discounts and earn rebates through the Bnx referral program.
                  <br />
                  For more information, please read the{" "}
                  <ExternalLink href="https://docs.bfx.finance/referrals">referral program details</ExternalLink>.
                </Trans>
              </div>
            </div>

            <div className="referral-tab-container">
              <div className="container">
                <Tab
                  options={TAB_OPTIONS}
                  optionLabels={TAB_OPTION_LABELS}
                  option={activeTab}
                  setOption={setActiveTab}
                  onChange={setActiveTab}
                />
              </div>
            </div>
          </div>
        </div>

        {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()}
      </div>
    </SEO>
  );
}

export default Referrals;
