import { useEffect, useRef, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { toJpeg } from "html-to-image";
import cx from "classnames";
import { BiCopy } from "react-icons/bi";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import { useCopyToClipboard, useMedia } from "react-use";
import Modal from "../Modal/Modal";
import kingLogo from "img/logo.svg";
import "./PositionShare.css";
import { QRCodeSVG } from "qrcode.react";
import { getHomeUrl, getRootShareApiUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { useAffiliateCodes } from "domain/referrals";
import SpinningLoader from "../Common/SpinningLoader";
import useLoadImage from "lib/useLoadImage";
import shareBgImg from "img/bg_share.svg";
import { helperToast } from "lib/helperToast";
import { formatAmount } from "lib/numbers";
import downloadImage from "lib/downloadImage";
import CopyIcon from "img/ic_copy.svg";
import DownloadIcon from "img/ic_download.svg";
import TweerIcon from "img/ic_tweet.svg";
import Checkbox from "components/Checkbox/Checkbox";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 518, canvasHeight: 292, type: "image/jpeg" };
function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref.success && ref.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}

function PositionShare({ setIsPositionShareModalOpen, isPositionShareModalOpen, positionToShare, account, chainId }) {
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const positionRef = useRef();
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.indexToken?.symbol} trade on @KING_IO`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "PNL Amount",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);
  useEffect(() => {
    (async function () {
      const element = positionRef.current;
      if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, config)
          .then(() => toJpeg(element, config))
          .then(() => toJpeg(element, config));
        try {
          const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(null);
          setUploadedImageError(t`Image generation error, please refresh and try again.`);
        }
      }
    })();
  }, [userAffiliateCode, sharePositionBgImg, positionToShare]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userAffiliateCode);
    copyToClipboard(url);
    helperToast.success(t`Link copied to clipboard.`);
  }
  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };
  return (
    <Modal
      className="position-share-modal"
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={t`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="checkbox-list">
        {checkList.map((item) => {
          return (
            <div className="checkbox-item" key={item.value}>
              <Checkbox isChecked={item.checked} setIsChecked={() => handleCheck(item)}>
                <div>{item.name}</div>
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="actions">
        <button disabled={!uploadedImageInfo} className="mr-base default-btn" onClick={handleCopy}>
          <img alt="" src={CopyIcon} style={{ marginRight: "10px" }} />
          <Trans>Copy</Trans>
        </button>
        <button disabled={!uploadedImageInfo} className="mr-base default-btn" onClick={handleDownload}>
          <img alt="" src={DownloadIcon} style={{ marginRight: "10px" }} />
          <Trans>Download</Trans>
        </button>
        <div className={cx("tweet-link-container default-btn", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <img alt="" src={TweerIcon} style={{ marginRight: "10px" }} />
            <Trans>Tweet</Trans>
          </a>
        </div>
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
}) {
  const isMobile = useMedia("(max-width: 600px)");
  const { code, success } = userAffiliateCode;
  const { deltaAfterFeesPercentageStr, isLong, leverage, indexToken, averagePrice, markPrice, deltaAfterFeesStr } =
    position;

  const homeURL = getHomeUrl();
  return (
    <div className="relative">
      <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
        <img className="logo" src={kingLogo} alt="Kingpin Logo" />
        <ul className="info">
          <li className={`side ${isLong ? "positive" : "negative"}`}>{isLong ? "Long" : "Short"}</li>
          <li className="stroke" />
          {checkList[0].checked && <li>{formatAmount(leverage, 4, 2, true)}x</li>}
          {checkList[0].checked && <li className="stroke" />}
          <li>{indexToken.symbol} USD</li>
        </ul>
        {checkList[1].checked && (
          <h3 className={`pnl ${deltaAfterFeesPercentageStr.includes("-") ? "negative" : "positive"}`}>
            {deltaAfterFeesPercentageStr}
            <span className="pnl-usd">({deltaAfterFeesStr})</span>
          </h3>
        )}
        {checkList[2].checked && (
          <div className="prices">
            <div className="price-line">
              <p>Entry Price:</p>
              <p className="price">${formatAmount(averagePrice, USD_DECIMALS, 2, true)}</p>
            </div>
            <div className="price-line">
              <p>Mark Price:</p>
              <p className="price">${formatAmount(markPrice, USD_DECIMALS, 2, true)}</p>
            </div>
          </div>
        )}
        <div className="referral-code">
          <div>
            <QRCodeSVG size={isMobile ? 30 : 56} value={success && code ? `${homeURL}/#/?ref=${code}` : `${homeURL}`} />
          </div>
          <div className="referral-code-info">
            {success && code ? (
              <>
                <p className="label">Referral Code:</p>
                <p className="code">{code}</p>
              </>
            ) : (
              <div className="qr-code-content">
                <p className="label">Trade now</p>
                <p className="code">https://kingpin.finance</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <Trans>Generating shareable image...</Trans>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShare;
