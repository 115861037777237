import { t, Trans } from "@lingui/macro";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { BigNumber } from "ethers";
import { formatKeyAmount } from "lib/numbers";

type Props = {
  processedData: {
    kingAprForEsKing: BigNumber;
    kingAprForNativeToken: BigNumber;
    kingAprForNativeTokenWithBoost: BigNumber;
    kingBoostAprForNativeToken?: BigNumber;
  };
  nativeTokenSymbol: string;
};

function renderEscrowedKINGApr(processedData) {
  if (!processedData?.kingAprForEsKing?.gt(0)) return;
  return (
    <StatsTooltipRow
      label={t`Escrowed KING APR`}
      showDollar={false}
      value={`${formatKeyAmount(processedData, "kingAprForEsKing", 2, 2, true)}%`}
    />
  );
}

export default function KINGAprTooltip({ processedData, nativeTokenSymbol }: Props) {
  return (
    <>
    <StatsTooltipRow
        label="Escrowed KING APR"
        showDollar={false}
        value={`${formatKeyAmount(processedData, "kingAprForEsKing", 2, 2, true)}%`}
      />
      {(!processedData.kingBoostAprForNativeToken || processedData.kingBoostAprForNativeToken.eq(0)) && (
        <StatsTooltipRow
          label={t`${nativeTokenSymbol} APR`}
          showDollar={false}
          value={`${formatKeyAmount(processedData, "kingAprForNativeToken", 2, 2, true)}%`}
        />
      )}
      {processedData?.kingBoostAprForNativeToken?.gt(0) ? (
        <div>
          <StatsTooltipRow
            label={t`${nativeTokenSymbol} Base APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "kingAprForNativeToken", 2, 2, true)}%`}
          />
          <StatsTooltipRow
            label={t`${nativeTokenSymbol} Boosted APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "kingBoostAprForNativeToken", 2, 2, true)}%`}
          />
          <div className="Tooltip-divider" />
          <StatsTooltipRow
            label={t`${nativeTokenSymbol} Total APR`}
            showDollar={false}
            value={`${formatKeyAmount(processedData, "kingAprForNativeTokenWithBoost", 2, 2, true)}%`}
          />
          <br />
          {renderEscrowedKINGApr(processedData)}
          <br />
          <Trans>The Boosted APR is from your staked Multiplier Points.</Trans>
        </div>
      ) : (
        renderEscrowedKINGApr(processedData)
      )}
      <div>
        <br />
        <Trans>APRs are updated weekly on Wednesday and will depend on the fees collected for the week.</Trans>
      </div>
    </>
  );
}
