import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import { getContract } from "config/contracts";

import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";

import Token from "abis/Token.json";
import Vester from "abis/Vester.json";
import RewardTracker from "abis/RewardTracker.json";
import RewardRouter from "abis/RewardRouter.json";

import { FaCheck, FaTimes } from "react-icons/fa";

import { Trans, t } from "@lingui/macro";

import "./BeginAccountTransfer.css";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import Button from "components/Button/Button";

function ValidationRow({ isValid, children }) {
  return (
    <div className="ValidationRow">
      <div className="ValidationRow-icon-container">
        {isValid && <FaCheck className="ValidationRow-icon" />}
        {!isValid && <FaTimes className="ValidationRow-icon" />}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default function BeginAccountTransfer(props) {
  const { setPendingTxns } = props;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [receiver, setReceiver] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isTransferSubmittedModalVisible, setIsTransferSubmittedModalVisible] = useState(false);
  let parsedReceiver = ethers.constants.AddressZero;
  if (ethers.utils.isAddress(receiver)) {
    parsedReceiver = receiver;
  }

  const kingAddress = getContract(chainId, "KING");
  const kingVesterAddress = getContract(chainId, "KingVester");
  const klpVesterAddress = getContract(chainId, "KlpVester");

  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const { data: kingVesterBalance } = useSWR(active && [active, chainId, kingVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(library, Token),
  });

  const { data: klpVesterBalance } = useSWR(active && [active, chainId, klpVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(library, Token),
  });

  const stakedKingTrackerAddress = getContract(chainId, "StakedKingTracker");
  const { data: cumulativeKingRewards } = useSWR(
    [active, chainId, stakedKingTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const stakedKlpTrackerAddress = getContract(chainId, "StakedKlpTracker");
  const { data: cumulativeKlpRewards } = useSWR(
    [active, chainId, stakedKlpTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: transferredCumulativeKingRewards } = useSWR(
    [active, chainId, kingVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const { data: transferredCumulativeKlpRewards } = useSWR(
    [active, chainId, klpVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const { data: pendingReceiver } = useSWR(
    active && [active, chainId, rewardRouterAddress, "pendingReceivers", account],
    {
      fetcher: contractFetcher(library, RewardRouter),
    }
  );

  const { data: kingAllowance } = useSWR(
    active && [active, chainId, kingAddress, "allowance", account, stakedKingTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: kingStaked } = useSWR(
    active && [active, chainId, stakedKingTrackerAddress, "depositBalances", account, kingAddress],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const needApproval = kingAllowance && kingStaked && kingStaked.gt(kingAllowance);

  const hasVestedKing = kingVesterBalance && kingVesterBalance.gt(0);
  const hasVestedKlp = klpVesterBalance && klpVesterBalance.gt(0);
  const hasStakedKing =
    (cumulativeKingRewards && cumulativeKingRewards.gt(0)) ||
    (transferredCumulativeKingRewards && transferredCumulativeKingRewards.gt(0));
  const hasStakedKlp =
    (cumulativeKlpRewards && cumulativeKlpRewards.gt(0)) ||
    (transferredCumulativeKlpRewards && transferredCumulativeKlpRewards.gt(0));
  const hasPendingReceiver = pendingReceiver && pendingReceiver !== ethers.constants.AddressZero;

  const getError = () => {
    if (!account) {
      return `Wallet is not connected`;
    }
    if (hasVestedKing) {
      return `Vested KING not withdrawn`;
    }
    if (hasVestedKlp) {
      return `Vested KLP not withdrawn`;
    }
    if (!receiver || receiver.length === 0) {
      return `Enter Receiver Address`;
    }
    if (!ethers.utils.isAddress(receiver)) {
      return `Invalid Receiver Address`;
    }
    if (hasStakedKing || hasStakedKlp) {
      return `Invalid Receiver`;
    }
    if ((parsedReceiver || "").toString().toLowerCase() === (account || "").toString().toLowerCase()) {
      return `Self-transfer not supported`;
    }

    if (
      (parsedReceiver || "").length > 0 &&
      (parsedReceiver || "").toString().toLowerCase() === (pendingReceiver || "").toString().toLowerCase()
    ) {
      return `Transfer already initiated`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isTransferring) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (needApproval) {
      return `Approve KING`;
    }
    if (isApproving) {
      return `Approving...`;
    }
    if (isTransferring) {
      return `Transferring`;
    }

    return `Begin Transfer`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: kingAddress,
        spender: stakedKingTrackerAddress,
        chainId,
      });
      return;
    }

    setIsTransferring(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, "signalTransfer", [parsedReceiver], {
      sentMsg: `Transfer submitted!`,
      failMsg: `Transfer failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsTransferSubmittedModalVisible(true);
      })
      .finally(() => {
        setIsTransferring(false);
      });
  };

  const completeTransferLink = `/complete_account_transfer/${account}/${parsedReceiver}`;
  const pendingTransferLink = `/complete_account_transfer/${account}/${pendingReceiver}`;

  return (
    <div className="BeginAccountTransfer default-container page-layout">
      <Modal
        isVisible={isTransferSubmittedModalVisible}
        setIsVisible={setIsTransferSubmittedModalVisible}
        label={t`Transfer Submitted`}
      >
        <Trans>Your transfer has been initiated.</Trans>
        <br />
        <br />
        <Link className="w-100 default-btn" to={completeTransferLink}>
          <Trans>Continue</Trans>
        </Link>
      </Modal>
      <div>
        <div className="Page-title">
          <Trans>Transfer Account</Trans>
        </div>
        <div className="Page-description">
          <div className="stats-des">Please only use this for full account transfers.</div>
          <div className="stats-des">
            {" "}
            This will transfer all your KING, esKING, KLP and Multiplier Points to your new account.
          </div>
          <div className="stats-des">
            Transfers are only supported if the receiving account has not staked KING or KLP tokens before.
          </div>
          <div className="stats-des">
            {" "}
            Transfers are one-way, you will not be able to transfer staked tokens back to the sending account.
          </div>
        </div>
        {hasPendingReceiver && (
          <div className="Page-description">
            <div className="stats-des">
              You have a <Link to={pendingTransferLink}>pending transfer</Link> to {pendingReceiver}.
            </div>
          </div>
        )}
      </div>
      <div className="" style={{ marginTop: "24px" }}>
        <div className="input-form">
          <div className="input-row">
            <label className="input-label">
              <Trans>Receiver Address</Trans>
            </label>
            <div>
              <input
                type="text"
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
                className="text-input"
              />
            </div>
          </div>
          <div className="BeginAccountTransfer-validations">
            <ValidationRow isValid={!hasVestedKing}>
              <Trans>Sender has withdrawn all tokens from KING Vesting Vault</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasVestedKlp}>
              <Trans>Sender has withdrawn all tokens from KLP Vesting Vault</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedKing}>
              <Trans>Receiver has not staked KING tokens before</Trans>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedKlp}>
              <Trans>Receiver has not staked KLP tokens before</Trans>
            </ValidationRow>
          </div>
          <div className="input-row">
            <button className="w-100 default-btn" disabled={!isPrimaryEnabled()} onClick={() => onClickPrimary()}>
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
