import React from "react";

import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "lib/legacy";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import KlpManager from "abis/KlpManager.json";

import { useWeb3React } from "@web3-react/core";

import { useKingPrice } from "domain/legacy";

import { getContract } from "config/contracts";
import { getServerUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { formatKeyAmount } from "lib/numbers";

export default function APRLabel({ chainId, label }) {
  let { active } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const kingAddress = getContract(chainId, "KING");
  const esKingAddress = getContract(chainId, "ES_KING");
  const bnKingAddress = getContract(chainId, "BN_KING");
  const klpAddress = getContract(chainId, "KLP");
  const usdkAddress = getContract(chainId, "USDK");
  const stakedKingTrackerAddress = getContract(chainId, "StakedKingTracker");
  const bonusKingTrackerAddress = getContract(chainId, "BonusKingTracker");
  const feeKingTrackerAddress = getContract(chainId, "FeeKingTracker");

  const stakedKlpTrackerAddress = getContract(chainId, "StakedKlpTracker");
  const feeKlpTrackerAddress = getContract(chainId, "FeeKlpTracker");

  const klpManagerAddress = getContract(chainId, "KlpManager");

  const kingVesterAddress = getContract(chainId, "KingVester");
  const klpVesterAddress = getContract(chainId, "KlpVester");

  const vesterAddresses = [kingVesterAddress, klpVesterAddress];

  const walletTokens = [kingAddress, esKingAddress, klpAddress, stakedKingTrackerAddress];
  const depositTokens = [
    kingAddress,
    esKingAddress,
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    bnKingAddress,
    klpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedKingTrackerAddress,
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    feeKingTrackerAddress,
    feeKingTrackerAddress,
    feeKlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedKingTrackerAddress,
    bonusKingTrackerAddress,
    feeKingTrackerAddress,
    stakedKlpTrackerAddress,
    feeKlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedKingSupply } = useSWR(
    [`StakeV2:stakedKingSupply:${active}`, chainId, kingAddress, "balanceOf", stakedKingTrackerAddress],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, klpManagerAddress, "getAums"], {
    fetcher: contractFetcher(undefined, KlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [vesterAddresses]),
    }
  );

  const { kingPrice } = useKingPrice(chainId, {}, active);

  // const kingSupplyUrl = getServerUrl(chainId, "/king_supply");
  // const { data: kingSupply } = useSWR([kingSupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  const tokensForSupplyQuery = [kingAddress, klpAddress, usdkAddress];
  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  let kingSupply;
  if (totalSupplies && totalSupplies[1]) {
    kingSupply = totalSupplies[1];
  }
  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedKingSupply,
    kingPrice,
    kingSupply
  );

  return <>{`${formatKeyAmount(processedData, label, 2, 2, true)}%`}</>;
}
