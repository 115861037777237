import { useMemo, useRef, useState } from "react";
import { Trans, t } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import { FiPlus, FiTwitter } from "react-icons/fi";
import { useCopyToClipboard, useMedia } from "react-use";
import { IoWarningOutline } from "react-icons/io5";
import { BiCopy, BiErrorCircle } from "react-icons/bi";
import Card from "../Common/Card";
import Modal from "../Modal/Modal";
import { shortenAddress } from "lib/legacy";
import EmptyMessage from "./EmptyMessage";
import InfoCard from "./InfoCard";
import {
  getReferralCodeTradeUrl,
  getTierIdDisplay,
  getTwitterShareUrl,
  getUSDValue,
  isRecentReferralCodeNotExpired,
  tierRebateInfo,
} from "./referralsHelper";
import { AffiliateCodeForm } from "./AddAffiliateCode";
import TooltipWithPortal from "../Tooltip/TooltipWithPortal";
import { FANTOM, AVALANCHE, OP, getExplorerUrl } from "config/chains";
import { helperToast } from "lib/helperToast";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";

import coppy_new from "img/coppy_new.svg";
import twitter_new from "img/ic_twitter_new.svg";
function AffiliatesStats({
  referralsData,
  handleCreateReferralCode,
  chainId,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}) {
  const [isAddReferralCodeModalOpen, setIsAddReferralCodeModalOpen] = useState(false);
  const addNewModalRef = useRef(null);

  const [, copyToClipboard] = useCopyToClipboard();
  const open = () => setIsAddReferralCodeModalOpen(true);
  const close = () => setIsAddReferralCodeModalOpen(false);

  const { referrerTotalStats, rebateDistributions } = referralsData || {
    cumulativeStats: {},
    referrerTotalStats: [],
    rebateDistributions: [],
    referrerTierInfo: {},
  };
  const allReferralCodes = referrerTotalStats.map((c) => c.referralCode.trim());
  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv);
        }
        return acc;
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, recentlyAddedCodes]
  );

  const isMobile = useMedia("(max-width: 600px)");

  return (
    <div className="referral-body-container">
      <div className="list">
        <Modal
          className="Connect-wallet-modal"
          isVisible={isAddReferralCodeModalOpen}
          setIsVisible={close}
          label={t`Referral Code`}
          onAfterOpen={() => addNewModalRef.current?.focus()}
        >
          <div className="edit-referral-modal">
            <AffiliateCodeForm
              handleCreateReferralCode={handleCreateReferralCode}
              recentlyAddedCodes={recentlyAddedCodes}
              setRecentlyAddedCodes={setRecentlyAddedCodes}
              callAfterSuccess={close}
            />
          </div>
        </Modal>

        {!isMobile ? (
          <>
            {finalAffiliatesTotalStats.length ? (
              <div className="reward-history">
                <div className="title">Referral Codes</div>

                <div className="header">
                  <div className="child">Referral Code</div>
                  <div className="child">Total Volume</div>
                  <div className="child">Traders Referred</div>
                  <div className="child">Total Rebates</div>
                  <div className="child no-header" />
                </div>

                {finalAffiliatesTotalStats.map((stat, index) => {
                  let referrerRebate = bigNumberify(0);
                  if (stat && stat?.totalRebateUsd && stat?.discountUsd) {
                    // referrerRebate = stat?.totalRebateUsd?.sub(stat?.discountUsd); remember
                  }
                  return (
                    <div className="content">
                      <div className="child">{stat.referralCode}</div>
                      <div className="child">{getUSDValue(stat.volume)}</div>
                      <div className="child">{stat.registeredReferralsCount}</div>
                      <div className="child">{getUSDValue(referrerRebate, 4)}</div>
                      <div className="child no-header">
                        <div className="table-referral-code">
                          <div
                            onClick={() => {
                              copyToClipboard(getReferralCodeTradeUrl(stat.referralCode));
                              helperToast.success("Referral link copied to your clipboard");
                            }}
                            className="referral-code-icon"
                          >
                            <img src={coppy_new} alt="coppy_new" />
                          </div>
                          <a
                            href={getTwitterShareUrl(stat.referralCode)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="referral-code-icon"
                          >
                            <img src={twitter_new} alt="twitter_new" style={{ width: "24px" }} />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="reward-history">
                <div className="title">Referral Codes</div>

                <EmptyMessage
                  message={t`No referral codes.`}
                  // tooltipText={t`Rebates are airdropped weekly.`}
                  tooltipText={t``}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {finalAffiliatesTotalStats.length ? (
              <div className="reward-history">
                <div className="title">Referral Codes</div>

                {finalAffiliatesTotalStats.map((stat, index) => {
                  let referrerRebate = bigNumberify(0);
                  if (stat && stat.totalRebateUsd && stat.discountUsd) {
                    // referrerRebate = stat.totalRebateUsd.sub(stat.discountUsd); remember
                  }
                  return (
                    <div className="content mobile">
                      <div className="mobile-wrapper">
                        <div className="mobile-title">Referral Code</div>
                        <div className="mobile-value">{stat.referralCode}</div>
                      </div>

                      <div className="mobile-wrapper">
                        <div className="mobile-title">Total Volume</div>
                        <div className="mobile-value">{getUSDValue(stat.volume)}</div>
                      </div>

                      <div className="mobile-wrapper">
                        <div className="mobile-title">Traders Referred</div>
                        <div className="mobile-value">{stat.registeredReferralsCount}</div>
                      </div>

                      <div className="mobile-wrapper">
                        <div className="mobile-title">Total Rebates</div>
                        <div className="mobile-value">{getUSDValue(referrerRebate, 4)}</div>
                      </div>

                      <div className="mobile-wrapper">
                        <div className="mobile-value">
                          <div className="table-referral-code">
                            <div
                              onClick={() => {
                                copyToClipboard(getReferralCodeTradeUrl(stat.referralCode));
                                helperToast.success("Referral link copied to your clipboard");
                              }}
                              className="referral-code-icon"
                            >
                              <img src={coppy_new} alt="coppy_new" />
                            </div>
                            <a
                              href={getTwitterShareUrl(stat.referralCode)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="referral-code-icon"
                            >
                              <img src={twitter_new} alt="twitter_new" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="reward-history">
                <div className="title">Referral Codes</div>

                <EmptyMessage
                  message={t`No referral codes.`}
                  // tooltipText={t`Rebates are airdropped weekly.`}
                  tooltipText={t``}
                />
              </div>
            )}
          </>
        )}
      </div>

      {!isMobile ? (
        <>
          {rebateDistributions.length > 0 ? (
            <div className="reward-history">
              <div className="title custom">
                <Tooltip
                  handle="Rewards Distribution History"
                  position="right-bottom"
                  renderContent={() => {
                    return <div>Rewards are airdropped to your wallet address.</div>;
                  }}
                />
              </div>

              <div className="header">
                <div className="child">Day</div>
                <div className="child">Amount</div>
                <div className="child">Transaction</div>
              </div>

              {rebateDistributions.map((rebate, index) => {
                let tokenInfo;
                try {
                  tokenInfo = getToken(chainId, rebate.token);
                } catch {
                  tokenInfo = getNativeToken(chainId);
                }
                const explorerURL = getExplorerUrl(chainId);
                return (
                  <div className="content">
                    <div className="child">{formatDate(rebate.timestamp)}</div>
                    <div className="child">
                      {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                    </div>
                    <div className="child Transaction">
                      <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                        {shortenAddress(rebate.transactionHash, 20)}
                      </ExternalLink>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="reward-history">
              <div className="title">Rewards Distribution History</div>

              <EmptyMessage
                message={t`No Rewards Distribution History yet.`}
                // tooltipText={t`Rebates are airdropped weekly.`}
                tooltipText={t``}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {rebateDistributions.length > 0 ? (
            <div className="reward-history">
              <div
                style={{
                  marginTop: 24,
                }}
                className="title"
              >
                Rewards Distribution History
              </div>

              {rebateDistributions.map((rebate, index) => {
                let tokenInfo;
                try {
                  tokenInfo = getToken(chainId, rebate.token);
                } catch {
                  tokenInfo = getNativeToken(chainId);
                }
                const explorerURL = getExplorerUrl(chainId);
                return (
                  <div className="content mobile">
                    <div className="mobile-wrapper">
                      <div className="mobile-title">Day</div>
                      <div className="mobile-value">{formatDate(rebate.timestamp)}</div>
                    </div>

                    <div className="mobile-wrapper">
                      <div className="mobile-title">Amount</div>
                      <div className="mobile-value">
                        {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                      </div>
                    </div>

                    <div className="mobile-wrapper">
                      <div className="mobile-title">Transaction</div>
                      <div className="mobile-value">
                        <ExternalLink href={explorerURL + `tx/${rebate.transactionHash}`}>
                          {shortenAddress(rebate.transactionHash, 20)}
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="reward-history">
              <div className="title">Rewards Distribution History</div>

              <EmptyMessage
                message={t`No Rewards Distribution History yet.`}
                // tooltipText={t`Rebates are airdropped weekly.`}
                tooltipText={t``}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AffiliatesStats;
