import { useWeb3React } from "@web3-react/core";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";

import InfoIc from "img/nft/info.svg";
import iconCheck from "img/nft/checked.svg";
import icPrev from "img/nft/ic-prev.svg";

import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";

import "./mintModal.scss";
import MummyClubSale from "abis/MummyClubSale.json";
import NFTClub from "abis/NFTClub.json";
import { ethers } from "ethers";

import { commify } from "@ethersproject/units";
import { getDocsClub, getLinkCDN } from "config/chains";
import { getContract } from "config/contracts";
import useProviderBalance from "hooks/useProviderBalance";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import Slider from "react-slick";
import { useMedia } from "react-use";
import useSWR from "swr";
import { Link } from "react-router-dom";

import imgNFT from "img/nft/item.png";

const abiDecoder = require("abi-decoder");
// eslint-disable-next-line no-sparse-arrays
const listOption = [
  {
    num: 1,
  },
  {
    num: 2,
  },
  {
    num: 3,
  },
  {
    num: 4,
  },
  ,
  {
    num: 5,
  },
];
export default function MintModal({ isVisible, setIsVisible, connectWallet, setPendingTxns, pendingTxns }) {
  const [minting, setMinting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedNum, setSelectedNum] = useState(listOption[0]);
  const handleClick = (item) => {
    setSelectedNum(item);
  };
  const isMobile = useMedia("(max-width: 676px)");

  const { active, activate, account: walletAccount, library } = useWeb3React();
  const { chainId } = useChainId();

  const ftmBalance = useProviderBalance();

  // const [nftId, setNFTId] = useState([]);
  const [dataMinted, setDataMinted] = useState([]);

  //   const nftClubAddress = getContract(chainId, "NFTClub");

  //   const MummyClubSaleAddress = getContract(chainId, "MummyClubSale");
  const estimateAmount = 10;
  //   const { data: estimateAmount } = useSWR(
  //     [`NFT:estimateAmount:${selectedNum.num}`, chainId, MummyClubSaleAddress, "estimateAmount"],
  //     {
  //       fetcher: contractFetcher(library, MummyClubSale, [selectedNum.num]),
  //     }
  //   );
  const currentPP = 0;
  //   const { data: currentPP } = useSWR([`NFT:currentPP:${active}`, chainId, MummyClubSaleAddress, "getCurrentPP"], {
  //     fetcher: contractFetcher(library, MummyClubSale),
  //   });
  //   const { data: totalSupply } = useSWR([`NFT:totalSupply:${active}`, chainId, nftClubAddress, "totalSupply"], {
  //     fetcher: contractFetcher(library, NFTClub),
  //   });

  const totalSupply = 100;
  // const { data: totalPower } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalPower"], {
  //   fetcher: contractFetcher(library, MummyClubSale),
  // });
  const totalVolume = 100;
  //   const { data: totalVolume } = useSWR([`NFT:totalSupply:${active}`, chainId, MummyClubSaleAddress, "totalVolume"], {
  //     fetcher: contractFetcher(library, MummyClubSale),
  //   });
  const nextPrice = 100;
  //   const nextPrice =
  //     currentPP && currentPP._mccPrice ? currentPP._mccPrice.mul(bigNumberify(101)).div(bigNumberify(100)) : 0;
  const nextPower = 0;
  //   const nextPower = currentPP && currentPP._mccPower ? (parseFloat(currentPP._mccPower) * 99) / 100 : 0;
  //   const nextBonus =
  //     currentPP && currentPP._esMMYBonus ? currentPP._esMMYBonus.mul(bigNumberify(99)).div(bigNumberify(100)) : 0;

  //   useEffect(() => {
  //     if (!isVisible) {
  //       setIsSuccess(false);
  //     }
  //   }, [isVisible]);

  //   const number = Number(totalSupply);
  const lastMintNFT = [];
  //   for (let index = number; index > number - 6 && index > 0; index--) {
  //     lastMintNFT.push(index);
  //   }

  const mintNFT = () => {};
  //   const mintNFT = () => {
  //     const opts = {
  //       value: estimateAmount?._totalPrice.toString(),
  //       // gasLimit: bigNumberify(7258590),
  //       successMsg: `Mint completed`,
  //       failMsg: `Mint failed`,
  //       sentMsg: `Mint submitted`,
  //       pendingTxns,
  //       setPendingTxns,
  //     };
  //     const params = [selectedNum.num];
  //     const method = "mintMummyClub";
  //     const contract = new ethers.Contract(MummyClubSaleAddress, MummyClubSale.abi, library.getSigner());
  //     callContract(chainId, contract, method, params, opts)
  //       .then(async (tx) => {
  //         try {
  //           setMinting(true);
  //           const receipt = await tx.wait();

  //           abiDecoder.addABI(MummyClubSale.abi);
  //           const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
  //           const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
  //           if (eventLog) {
  //             const listData = [];
  //             eventLog.forEach((element) => {
  //               listData.push({
  //                 nftID: element.events[1].value,
  //                 power: element.events[2].value,
  //                 bonus: element.events[3].value,
  //               });
  //             });
  //             // setNFTId(listData);
  //             setDataMinted(listData);
  //           }
  //           setIsSuccess(true);
  //         } catch (error) {
  //           console.log(error);
  //           // history.push("/your-nft")
  //         }
  //       })
  //       .finally(() => {
  //         setMinting(false);
  //       });
  //     //TODO
  //     //   const receipt = {
  //     //     "to": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //     "from": "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //     "contractAddress": null,
  //     //     "transactionIndex": 1,
  //     //     "gasUsed": {
  //     //         "type": "BigNumber",
  //     //         "hex": "0x0830f6"
  //     //     },
  //     //     "logsBloom": "0x00000000000000000000000000000000000000000000000000000000000200000082000000000000000000100000000000008000000020000000000000008000000000000000000000000108000000800001000000000000000100000000000001000000020002000000000000000800000000000000040080000010000000000000000000000000000000200000000000000000000000000000000000000080200000000100002000080000002000000000000000000000000000002000004000100002000010000001000000001100000020000000800000108008000020000000000000000000000000100000010008000000000000000000000002100000",
  //     //     "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //     "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //     "logs": [
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x0000000000000000000000000000000000001010",
  //     //             "topics": [
  //     //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
  //     //             "logIndex": 2,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
  //     //             "logIndex": 3,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 4,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 5,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
  //     //             "logIndex": 6,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 7,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 8,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
  //     //             "logIndex": 9,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 10,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 11,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x0000000000000000000000000000000000001010",
  //     //             "topics": [
  //     //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
  //     //             ],
  //     //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
  //     //             "logIndex": 12,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         }
  //     //     ],
  //     //     "blockNumber": 29787980,
  //     //     "confirmations": 4,
  //     //     "cumulativeGasUsed": {
  //     //         "type": "BigNumber",
  //     //         "hex": "0x08e586"
  //     //     },
  //     //     "effectiveGasPrice": {
  //     //         "type": "BigNumber",
  //     //         "hex": "0x0843f7031a"
  //     //     },
  //     //     "status": 1,
  //     //     "type": 2,
  //     //     "byzantium": true,
  //     //     "events": [
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x0000000000000000000000000000000000001010",
  //     //             "topics": [
  //     //                 "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000000000d9a505f7fa2e000000000000000000000000000000000000000000000000003c386f53c670c9700000000000000000000000000000000000000000000000000799dce5f9df2c000000000000000000000000000000000000000000000000003b5eca4dce769b70000000000000000000000000000000000000000000000000087381ebf1d95a0",
  //     //             "logIndex": 2,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000026",
  //     //             "logIndex": 3,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x26"
  //     //                 }
  //     //             ],
  //     //             "event": "AssetMinted",
  //     //             "eventSignature": "AssetMinted(address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000026"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 4,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0x0000000000000000000000000000000000000000",
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x26"
  //     //                 }
  //     //             ],
  //     //             "event": "Transfer",
  //     //             "eventSignature": "Transfer(address,address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 5,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000027",
  //     //             "logIndex": 6,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x27"
  //     //                 }
  //     //             ],
  //     //             "event": "AssetMinted",
  //     //             "eventSignature": "AssetMinted(address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000027"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 7,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0x0000000000000000000000000000000000000000",
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x27"
  //     //                 }
  //     //             ],
  //     //             "event": "Transfer",
  //     //             "eventSignature": "Transfer(address,address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 8,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0x8d1457c1d60a6987eabbac898a50d8d7c81ba604c563663d2807027a4b079054"
  //     //             ],
  //     //             "data": "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d50000000000000000000000000000000000000000000000000000000000000028",
  //     //             "logIndex": 9,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x28"
  //     //                 }
  //     //             ],
  //     //             "event": "AssetMinted",
  //     //             "eventSignature": "AssetMinted(address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0xBf064Aa32DbEC74F176F7a24380c64b06ae35767",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000000",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000000028"
  //     //             ],
  //     //             "data": "0x",
  //     //             "logIndex": 10,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db",
  //     //             "args": [
  //     //                 "0x0000000000000000000000000000000000000000",
  //     //                 "0xbdb0831e31e178820654DdD7cC8902C22c9280d5",
  //     //                 {
  //     //                     "type": "BigNumber",
  //     //                     "hex": "0x28"
  //     //                 }
  //     //             ],
  //     //             "event": "Transfer",
  //     //             "eventSignature": "Transfer(address,address,uint256)"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x739E5C12DEf097ece09020902F0C3E432997d22e",
  //     //             "topics": [
  //     //                 "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
  //     //                 "0x000000000000000000000000bf064aa32dbec74f176f7a24380c64b06ae35767",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5"
  //     //             ],
  //     //             "data": "0x000000000000000000000000000000000000000000000002733896c68d9a4000",
  //     //             "logIndex": 11,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         },
  //     //         {
  //     //             "transactionIndex": 1,
  //     //             "blockNumber": 29787980,
  //     //             "transactionHash": "0x393bd8ad1f58656c74c678909a51bd9da3baa4184ca1396d6e7e2a0085089def",
  //     //             "address": "0x0000000000000000000000000000000000001010",
  //     //             "topics": [
  //     //                 "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
  //     //                 "0x0000000000000000000000000000000000000000000000000000000000001010",
  //     //                 "0x000000000000000000000000bdb0831e31e178820654ddd7cc8902c22c9280d5",
  //     //                 "0x0000000000000000000000003a22c8bc68e98b0faf40f349dd2b2890fae01484"
  //     //             ],
  //     //             "data": "0x0000000000000000000000000000000000000000000000000043b467b83ecb9c000000000000000000000000000000000000000000000000040eb1c19bccc3ed00000000000000000000000000000000000000000000010c455e0c95b2fcabf100000000000000000000000000000000000000000000000003cafd59e38df85100000000000000000000000000000000000000000000010c45a1c0fd6b3b778d",
  //     //             "logIndex": 12,
  //     //             "blockHash": "0xb26ef1242a9af2f1e4a393a70d02b46943c488fc5c238303646a69f817fd77db"
  //     //         }
  //     //     ]
  //     // }
  //     //   abiDecoder.addABI(NFTClub.abi);
  //     //   const decodedLogs = abiDecoder.decodeLogs(receipt.logs);
  //     //   const eventLog = decodedLogs.filter((item) => item.name === "AssetMinted");
  //     //   if (eventLog) {
  //     //     const listID = eventLog.map((item) => item.events[1].value)
  //     //     setNFTId(listID)
  //     //   }
  //     //   setIsSuccess(true);
  //     //   setMinting(false);
  //   };

  const renderContent = () => {
    if (minting) return <ContentLoading />;
    if (isSuccess) {
      return (
        <SuccessContent
          nftId={dataMinted}
          //   mccPower={currentPP?._mccPower}
          //   esMMYBonus={estimateAmount?._totalBonus}
          mccPower={100}
          esMMYBonus={100}
          setIsSuccess={setIsSuccess}
        />
      );
    }

    return (
      <div className="modal-wrap">
        <div className="mint-info">
          <div className="mint-info-content">
            <div className="mint-stats">
              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Bonus</div>
                  <div className="mint-stats-amount">
                    1,600 esKING
                    {/* {formatAmount(currentPP?._esMMYBonus, 18, 2, true) + " esKING"} */}
                  </div>
                </div>
                <div>
                  <div className="mint-stats-label">Next Bonus</div>
                  <div className="mint-stats-amount">
                    1,600 esKING
                    {/* {formatAmount(currentPP?._esMMYBonus, 18, 2, true) + " esKING"} */}
                  </div>
                </div>
              </div>

              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Price</div>
                  <div className="mint-stats-amount">
                    {" "}
                    {/* {formatAmount(currentPP?._mccPrice, 18, 2, true)} */}0,01 KAVA{" "}
                    <div className="mint-stats-amount-dollar">$50.00</div>
                  </div>
                </div>{" "}
                <div>
                  <div className="mint-stats-label">
                    Next Price
                    <Tooltip
                      position={`${isMobile ? "left-bottom " : "right-bottom"} `}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "Price increases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">
                    {/* {formatAmount(nextPrice, 18, 2, true)} */}
                    0.01 KAVA
                    <div className="mint-stats-amount-dollar">$50.00</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="first-box">
                  <div className="mint-stats-label">Current Power</div>
                  <div className="mint-stats-amount">{/* {formatAmount(currentPP?._mccPower, 0, 0, true)} */} 0</div>
                </div>
                <div>
                  <div className="mint-stats-label">
                    Next Power
                    <Tooltip
                      position={`${isMobile ? "left-bottom" : "right-bottom "}`}
                      handle={<img alt="mint" src={InfoIc} />}
                      renderContent={() => {
                        return "Power decreases by 1% for each group of 100 NFTs minted";
                      }}
                    ></Tooltip>
                  </div>
                  <div className="mint-stats-amount">{commify(nextPower.toFixed(0))}</div>
                </div>
              </div>
            </div>
            <div className="balance-box">
              <div className="number-nft"> Select number of NFT</div>

              <div className="select-box-nft">
                {listOption.map((item, id) => {
                  return (
                    <div
                      className={`item-nft ${selectedNum.num === item.num ? "item-nft--active" : ""}`}
                      onClick={() => handleClick(item)}
                    >
                      {selectedNum.num === item.num ? <img alt="check" src={iconCheck} className="check-ic" /> : null}
                      <div className="nft-amount">{item.num} </div>
                      <div className="nft-price">{item.num} KAVA</div>
                      <div className="nft-price-dollar">$50</div>
                    </div>
                  );
                })}
              </div>

              <div>
                <div className="receiver-info">
                  <div>
                    <div className="your-balance">
                      Your balance:
                      <span> {formatAmount(ftmBalance, 18, 2, true)} KAVA</span>{" "}
                      <div className="mint-stats-amount-dollar">$50.00</div>
                    </div>
                    <div className="your-pay">
                      You will pay:
                      <span> -- KAVA</span> <div className="mint-stats-amount-dollar">$50.00</div>
                    </div>
                  </div>
                  <div>
                    <div className="your-pay">
                      NFT received:
                      <span>1</span>{" "}
                    </div>
                    <div className="your-pay">
                      Bonus received:
                      <span>1,600 esKING</span>{" "}
                    </div>
                  </div>
                </div>

                {!active ? (
                  <button className="default-btn w-100" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                ) : estimateAmount &&
                  estimateAmount?._totalPrice &&
                  estimateAmount._totalPrice.lt(ftmBalance) &&
                  Number(totalSupply) <= 5000 ? (
                  <button className="default-btn w-100" disabled={Date.now() <= 1671462000000} onClick={mintNFT}>
                    {" "}
                    {/* <img alt="mint " src={MintIc} />  */}
                    Mint NFT
                  </button>
                ) : (
                  <button className="default-btn disable w-100" disabled>
                    {Number(totalSupply) <= 5000 ? `Insufficient KAVA balance` : "Sold out"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        label={isSuccess ? "Minting completed" : minting ? "" : "Mint NFT"}
        className={isSuccess ? "modal-success" : minting ? "modal-minting" : "modal-mint"}
        isVisible={isVisible}
        // isVisible
        setIsVisible={minting ? () => {} : setIsVisible}
      >
        {renderContent()}
      </Modal>
    </div>
  );
}

function SuccessContent(props) {
  const { nftId, mccPower, esMMYBonus, setPendingTxns, setIsSuccess } = props;
  const { chainId } = useChainId();
  const setting = {
    dots: true,
    infinite: true,
    speed: 1000,

    autoplay: false,

    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  let bonus = bigNumberify(0);
  nftId.forEach((element) => {
    bonus = bonus.add(parseValue(element.bonus, 0));
  });

  const listNFT = [
    {
      img: imgNFT,
      nftId: 1,
    },
    {
      img: imgNFT,
      nftId: 2,
    },
  ];
  return (
    <div className="success-wrap">
      <div className="success-content">
        {" "}
        <div className="success-desc">
          <div>
            NFT received: <span>{nftId.length} </span>
          </div>
          <div>
            Bonus received: <span>{formatAmount(bonus, 18, 2, true)}</span> esKING
          </div>
        </div>
        <div className="slider-wrapper">
          <Slider {...setting}>
            {/* {nftId.map((item, id) => { */}
            {listNFT.map((item, id) => {
              return (
                <div className="item-nft" key={id}>
                  <img src={` ${item.img} `} alt="img" />
                  {/* ${getLinkCDN(chainId)}${item.nftID}.png` */}
                  {/* <div className="nft-name">Mummy #{item.nftID}</div>
                  <div className="nft-info">
                    <div className="nft-power nft-info-power ">
                      Power: &nbsp; <span>{formatAmount(item.power, 0, 0, true)}</span>
                    </div>{" "}
                    <div className="nft-power">
                      Bonus: <span>{formatAmount(item.bonus, 18, 2, true)} esMMY</span>
                    </div> 
                  </div>*/}
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="nft-action">
          <button className="default-btn" onClick={() => setIsSuccess(false)}>
            Mint more NFT
          </button>
          <Link className="outline-btn" to="/nft">
            Go to your NFT
          </Link>
        </div>
      </div>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={icPrev} alt="img" className="ic-next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      {" "}
      <img src={icPrev} alt="img" />
    </div>
  );
}

function ContentLoading() {
  const [widthPercent, setWidthPercent] = useState(0);

  useEffect(() => {
    let width = 0;
    let timmer;

    timmer = setInterval(() => {
      if (width < 90) {
        width += 1;
        setWidthPercent(width);
      }
    }, 500);

    return () => {
      clearInterval(timmer);
    };
  }, []);
  return (
    <div className="loading-wrap">
      <div className="loading-circle"></div>
      <div className="loading-percent">Minting NFT: {widthPercent}%</div>
    </div>
  );
}
